<template>
  <div>
    <h1>Goodbye {{upperMsg}}</h1>
  </div>
</template>

<script lang="ts">

import {HelloWorld, default as _vueclass_super} from './HelloWorld.vue';
import Truss from 'firetruss';
import ':core/truss';
import {defineComponent as _vueclass_defineComponent} from ':core/vue-class';
export class GoodbyeWorld extends HelloWorld {
  upperMsg = '';

  created() {
    (async() => {
      this.upperMsg = await Truss.worker.callServerFunction('toUpperCase', {text: this.msg});
    })();
  }
}
export default _vueclass_defineComponent(GoodbyeWorld, 'GoodbyeWorld', _vueclass_super);

</script>
