<template>
  <v-app>
    <a href="https://vitejs.dev" target="_blank">
      <img src="/vite.svg" class="logo" alt="Vite logo"/>
    </a>
    <a href="https://vuejs.org/" target="_blank">
      <img src="./assets/vue.svg" class="logo vue" alt="Vue logo"/>
    </a>
    <HelloWorld msg="Vite + Vue"/>
    <GoodbyeWorld msg="world"/>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import {defineComponent as _vueclass_defineComponent} from ':core/vue-class';
export class App extends Vue {
}
export default _vueclass_defineComponent(App, 'App');

</script>


<style scoped>
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}
</style>
